import {
  Box,
  Button,
  Grid,
  Image,
  RingProgress,
  Stack,
  Text,
  Title
} from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import { useContext, useState } from "react";
import { LanguageContext } from "../contexts/LanguageContext";
import "./typewriter.css";

type TypeWriterProps = {
  texts: string[];
  images: string[];
  typeSpeed?: number;
  ereaseSpeed?: number;
  duration?: number;
  className?: string;
  onChange?: Function;
};
function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

enum State {
  type,
  erease,
  complete,
}



export default function TypeWriter({
  texts,
  typeSpeed = 10,
  className,
  images,
}: TypeWriterProps) {
  const [current, setCurrent] = useState("");
  const [index, setIndex] = useState(0);
  const [status, setStatus] = useState(State.type);
  const {width } = useViewportSize();
  const language = useContext(LanguageContext);

  if (status === State.type) {
    type(texts[index]);
  }

  function currentHandler(text: string, length: number) {
    setCurrent(text.slice(0, length));
  }

  function indexHandler() {
    let temp = index;
    if (index < texts.length - 1) {
      setIndex(temp + 1);
    } else {
      setIndex(0);
    }
    setCurrent("");
    setStatus(State.type);
  }

  function type(text: string) {
    if (current.length < text.length) {
      sleep(500 / typeSpeed).then(() => {
        currentHandler(text, current.length + 1);
      });
    } else {
      setStatus(State.complete);
    }
  }


  return (
    <Grid justify="center" align="center">
      <Grid.Col display={"flex"} sx={{ justifyContent: "center" }} md={6}>
        {status !== State.complete ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "400px",
              width: "400px",
              backgroundColor: "white",
            }}
          >
            <RingProgress
              sections={[
                {
                  value: (current.length / texts[index].length) * 100,
                  color: "black",
                },
              ]}
            />
          </Box>
        ) : (
          <Image
          sx={{minHeight:400, backgroundColor:"white"}}
            width={400}
            src={images[index]}
          ></Image>
        )}
      </Grid.Col>

      <Grid.Col md={6}>
        <Stack sx={width < 992 ? {minHeight:180}:{}}>
          <Title
            sx={() => ({
              background: "black",
              color: "white",
            })}
            ta="left"
            order={width>600? 2:3}
            fw={700}
          >
            <Text className={className}>
              {status === State.complete ? texts[index] : current}
              <span className="blinkingCursor">|</span>
            </Text>
          </Title>
          {status === State.complete ? (
            <Button
            pl={25}
            pr={25}
              sx={{
                width:"fit-content",
                borderRadius: 100,
                color: "white",
                borderColor: "white",
                backgroundColor: "transparent",
                transition: "0.4s",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                  borderColor: "white",
                },
              }}
              onClick={() => {
                indexHandler();
              }}
            >
              {language.strings.typewriterNextButton}
            </Button>
          ) : (
            <></>
          )}
        </Stack>
      </Grid.Col>
    </Grid>
  );
}
